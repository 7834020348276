// import '../styles/globals.css'
// @ts-ignore
import ErrorBoundary from "components/common/errorBoundary";
// @ts-ignore
import { Spinner } from "components/common/spinner";
import { useRouter } from "next/router";
import Script from "next/script";
import { SessionProvider } from "next-auth/react";
// @ts-ignore
import NProgress from "nprogress";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { makeStore, persistor } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";

import "../components/videojs/components/skins/roundal/videojs.min.css";
// import "../styles/mixins/global.scss";
import "../styles/style.css";
// import "../styles/theme.css";
import "react-datepicker/dist/react-datepicker.css";
import "svgmap/dist/svgMap.min.css";
import "../styles/nprogress.css";
import "../styles/globals.css";

function MyApp({ Component, pageProps: { session, ...pageProps } }: any) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteStart = () => NProgress.start();
    const handleRouteDone = () => NProgress.done();

    router.events.on("routeChangeStart", handleRouteStart);
    router.events.on("routeChangeComplete", handleRouteDone);
    router.events.on("routeChangeError", handleRouteDone);

    return () => {
      // Make sure to remove the event handler on unmount!
      router.events.off("routeChangeStart", handleRouteStart);
      router.events.off("routeChangeComplete", handleRouteDone);
      router.events.off("routeChangeError", handleRouteDone);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <SessionProvider session={session}>
        <Provider store={makeStore}>
          <PersistGate loading={<Spinner />} persistor={persistor}>
            <ErrorBoundary>
              <Script
                src="https://www.googletagmanager.com/gtag/js?id=G-925PQJ5EL2"
                strategy="afterInteractive"
              />
              <Script id="google-analytics" strategy="afterInteractive">
                {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-925PQJ5EL2');
        `}
              </Script>
              <Toaster />
              <Component {...pageProps} />
            </ErrorBoundary>
          </PersistGate>
        </Provider>
      </SessionProvider>
    </>
  );
}

export default MyApp;
