import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQueryWithInterceptor = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    localStorage.clear();
    window.location.href = "/";
  }

  return result;
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const { token } = getState().auth;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseApiSlice = createApi({
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    saveShowInfo: builder.mutation({
      query: ({ body }) => ({
        url: `/shows`,
        method: "POST",
        body,
      }),
    }),
    updateShowInfo: builder.mutation({
      query: ({ obj, showId }) => ({
        url: `/shows/${showId}`,
        method: "PATCH",
        body: obj,
      }),
    }),
    getShowInfo: builder.mutation({
      query: ({ showId }) => ({
        url: `/shows/${showId}`,
        method: "GET",
      }),
    }),
    getShow: builder.query({
      query: ({ showId }) => ({
        url: `/shows/${showId}`,
        method: "GET",
      }),
    }),
    cancelShow: builder.mutation({
      query: ({ showId }) => ({
        url: `/shows/cancel/${showId}`,
        method: "POST",
      }),
    }),
    getShowInfoUsingStreamID: builder.mutation({
      query: ({ showId }) => ({
        url: `/shows/show/${showId}`,
        method: "GET",
      }),
    }),
    getShowInfoUsingStreamIDFetch: builder.query({
      query: (streamID) => ({
        url: `/shows/show/${streamID}`,
        method: "GET",
      }),
    }),
    deleteShow: builder.mutation({
      query: ({ showId }) => ({
        url: `/shows/${showId}`,
        method: "DELETE",
      }),
    }),
    getStoreStats: builder.query({
      query: (shopId) => `/shows/stats/${shopId}`,
    }),
    getLastWeekViews: builder.query({
      query: (streamID) => ({
        url: `/shows/views/${streamID}/lastweek`,
        method: "GET",
      }),
    }),
    getAllShows: builder.query({
      query: ({ page, shopId }) => `/shows/store/${shopId}?page=${page}`,
      keepUnusedDataFor: 15,
    }),
    uploadShowMedia: builder.mutation({
      query: ({ formData, showId }) => ({
        url: `/shows/media/${showId}`,
        method: "PATCH",
        body: formData,
      }),
    }),
    createProduct: builder.mutation({
      query: ({ formData }) => ({
        url: `/products`,
        method: "POST",
        body: formData,
      }),
    }),
    editProduct: builder.mutation({
      query: ({ formData, productId }) => ({
        url: `/products/${productId}`,
        method: "PATCH",
        body: formData,
      }),
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/products/${id}`,
        method: "DELETE",
      }),
    }),
    getAllProducts: builder.query({
      query: ({ page, shopId }) => `/products/store/${shopId}?page=${page}`,
    }),
    getAllProductsAll: builder.query({
      query: () => `/products/all`,
    }),
    getAllProductsCount: builder.query({
      query: () => `/products/count`,
    }),
    getAllShowMessages: builder.query({
      query: (streamID) => `/chats/message/${streamID}`,
    }),
    getCountOfShowMessages: builder.query({
      query: (streamID) => `/chats/count/${streamID}`,
    }),
    getUserByEmail: builder.mutation({
      query: ({ email }) => ({
        url: `/users/email`,
        body: {
          email,
        },
        method: "POST",
      }),
    }),
    getUserByEmailFetch: builder.query({
      query: (email) => ({
        url: `/users/email`,
        body: {
          email,
        },
        method: "POST",
      }),
    }),
    getUserById: builder.query({
      query: (id) => ({
        url: `/users/${id}`,
      }),
    }),
    updateUserProfile: builder.mutation({
      query: ({ obj, userId }) => ({
        url: `/users/${userId}`,
        method: "PATCH",
        body: obj,
      }),
    }),
    createShop: builder.mutation({
      query: ({ formData }) => ({
        url: `/stores`,
        method: "POST",
        body: formData,
      }),
    }),
    updateShop: builder.mutation({
      query: ({ formData, shopId }) => ({
        url: `/stores/${shopId}`,
        method: "PATCH",
        body: formData,
      }),
    }),
    getShopInfo: builder.query({
      query: (shopId) => ({
        url: `/stores/${shopId}`,
        method: "GET",
      }),
    }),
    getShopInfoPost: builder.mutation({
      query: ({ storeId }) => ({
        url: `/stores/${storeId}`,
        method: "GET",
      }),
    }),
    transitionShopToReview: builder.mutation({
      query: ({ shopId }) => ({
        url: `/stores/${shopId}/transition`,
        method: "POST",
      }),
    }),
    verifyEmail: builder.query({
      query: ({ token, user }) => ({
        url: `/users/verify-email`,
        method: "POST",
        body: {
          token,
          userId: user,
        },
      }),
    }),
    resendActivationEmail: builder.mutation({
      query: ({ body }) => ({
        url: `/users/resend-activation-link`,
        method: "POST",
        body,
      }),
    }),
    followStore: builder.mutation({
      query: ({ storeId, userId }) => ({
        url: `/users/follow/${userId}/${storeId}`,
        method: "POST",
      }),
    }),
    unfollowStore: builder.mutation({
      query: ({ storeId, userId }) => ({
        url: `/users/unfollow/${userId}/${storeId}`,
        method: "POST",
      }),
    }),
    userCheck: builder.query({
      query: () => `/users/auth/check-user-active`,
      keepUnusedDataFor: 5,
    }),
    requestPasswordReset: builder.mutation({
      query: ({ body }) => ({
        url: `/users/request-reset-password`,
        body,
        method: "POST",
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ body }) => ({
        url: `/users/reset-password`,
        body,
        method: "POST",
      }),
    }),
    getShowAnalytics: builder.query({
      query: (streamIDShow) => `/analytics/${streamIDShow}`,
    }),
    getStoreAnalytics: builder.query({
      query: (storeId) => `/analytics/store/${storeId}`,
    }),
    showHasAnalytics: builder.query({
      query: (streamId) => `/analytics/has/${streamId}`,
    }),
    getRecentComments: builder.query({
      query: ({ storeId, userId }) => `/chats/store/${storeId}/${userId}`,
    }),
    getLibrary: builder.query({
      query: (storeId) => `/library/${storeId}`,
    }),
    updateVideoName: builder.mutation({
      query: ({ body }) => ({
        url: `/library/update-name`,
        body,
        method: "PUT",
      }),
    }),
    getTzwPlans: builder.query({
      query: () => `/users/subscriptions/tzw-plans`,
      keepUnusedDataFor: 60 * 5,
    }),
    getUsage: builder.query({
      query: () => `/users/subscriptions/usage`,
    }),
    getFeatures: builder.query({
      query: () => `/users/subscriptions/features`,
    }),
    cancelOldSub: builder.query({
      query: () => `/users/subscriptions/cancel-old`,
    }),
  }),
});

export const {
  useSaveShowInfoMutation,
  useCreateProductMutation,
  useGetAllProductsQuery,
  useUpdateShowInfoMutation,
  useUploadShowMediaMutation,
  useGetShowInfoMutation,
  useGetAllShowsQuery,
  useDeleteShowMutation,
  useGetShowInfoUsingStreamIDMutation,
  useCancelShowMutation,
  useGetAllProductsCountQuery,
  useGetAllProductsAllQuery,
  useGetAllShowMessagesQuery,
  useGetCountOfShowMessagesQuery,
  useGetUserByEmailMutation,
  useUpdateUserProfileMutation,
  useCreateShopMutation,
  useUpdateShopMutation,
  useGetShopInfoQuery,
  useTransitionShopToReviewMutation,
  useVerifyEmailQuery,
  useResendActivationEmailMutation,
  useGetShopInfoPostMutation,
  useFollowStoreMutation,
  useUnfollowStoreMutation,
  useGetUserByEmailFetchQuery,
  useDeleteProductMutation,
  useEditProductMutation,
  useGetStoreStatsQuery,
  useUserCheckQuery,
  useGetShowInfoUsingStreamIDFetchQuery,
  useGetLastWeekViewsQuery,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
  useGetShowAnalyticsQuery,
  useGetStoreAnalyticsQuery,
  useGetRecentCommentsQuery,
  useGetLibraryQuery,
  useUpdateVideoNameMutation,
  useGetTzwPlansQuery,
  useGetUsageQuery,
  useGetShowQuery,
  useShowHasAnalyticsQuery,
  useGetUserByIdQuery,
  useGetFeaturesQuery,
  useCancelOldSubQuery,
} = baseApiSlice;
