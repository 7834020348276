// @ts-ignore
import Seo from "components/common/seo";
import React from "react";

export default function Custom500() {
  return (
    <>
      <Seo title="Error - Something went wrong" />
      <div className="flex h-screen w-screen flex-col items-center justify-center gap-6 bg-white-50 text-gray-200">
        <div className="flex flex-col gap-4 items-center">
          <h1 className="text-xl font-bold text-gray-200">Error</h1>
          <p className="text-md font-medium">Something went wrong</p>
        </div>
        <button
          className="bg-gray-200 rounded-3xl px-12 py-2 font-semibold text-white-50"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Go Home
        </button>
      </div>
    </>
  );
}
