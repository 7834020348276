/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  refreshToken: "",
  userProfile: "",
  businessProfile: undefined,
  businessDetails: {
    shopSystem: "",
    industry: "",
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state) => {
      state.token = "";
      state.refreshToken = "";
      state.userProfile = undefined;
      state.businessProfile = undefined;
    },
    // set token
    setToken: (state, action) => {
      state.token = action.payload;
    },
    // set refresh token
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    setBusinessProfile: (state, action) => {
      state.businessProfile = action.payload;
    },
    setBusinessDetails: (state, action) => {
      state.businessDetails = action.payload;
    },
  },
});
export const {
  resetAuth,
  setToken,
  setRefreshToken,
  setUserProfile,
  setBusinessProfile,
  setBusinessDetails,
} = authSlice.actions;
export default authSlice.reducer;
