/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  savedShow: {},
  products: [],
  productsForShowIds: [],
  completedSteps: [],
  openedSidebar: true,
  plans: undefined,
  offsite: undefined,
  onsite: undefined,
  showNameOnEdit: "",
  createLiveShow: false,
  showPickVideo: false,
  selectedVideo: undefined,
};

export const showSlice = createSlice({
  name: "show",
  initialState,
  reducers: {
    resetShowState: (state) => {
      state.savedShow = {};
      state.products = [];
      state.productsForShowIds = [];
      state.completedSteps = [];
      state.plans = undefined;
      state.offsite = undefined;
      state.onsite = undefined;
      state.createLiveShow = false;
    },
    setSavedShow: (state, action) => {
      state.savedShow = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setProductsForShowIds: (state, action) => {
      state.productsForShowIds = action.payload;
    },
    setCompletedSteps: (state, action) => {
      state.completedSteps = action.payload;
    },
    toggleSidebar: (state, action) => {
      state.openedSidebar = action.payload;
    },
    setPlans: (state, action) => {
      state.plans = action.payload;
    },
    setOffsite: (state, action) => {
      state.offsite = action.payload;
    },
    setOnSite: (state, action) => {
      state.onsite = action.payload;
    },
    setShowNameOnEdit: (state, action) => {
      state.showNameOnEdit = action.payload;
    },
    setCreateLiveShow: (state, action) => {
      state.createLiveShow = action.payload;
    },
    setShowPickVideo: (state, action) => {
      state.showPickVideo = action.payload;
    },
    setSelectedVideo: (state, action) => {
      state.selectedVideo = action.payload;
    },
  },
});
export const {
  resetShowState,
  setSavedShow,
  setProducts,
  setProductsForShowIds,
  setCompletedSteps,
  toggleSidebar,
  setPlans,
  setOffsite,
  setOnSite,
  setShowNameOnEdit,
  setCreateLiveShow,
  setShowPickVideo,
  setSelectedVideo,
} = showSlice.actions;
export default showSlice.reducer;
